export const validationMessages = {
    BAD_REQUEST: 'bad_request',
    CONFLICT: 'conflict',
    ERROR: 'error',
    IS_NULL: 'is_null',
    IS_BLANK: 'is_blank',
    OUT_OF_RANGE: 'out_of_range',
    MESSAGE_BAD_REQUEST: 'message_bad_request',
    IS_EMPTY: 'is_empty',
    SERVICE_START_CURRENT: 'service_start_current',
    SERVICE_MIN_DURATION: 'service_min_duration',
    ADD_SERVICE_OUT_OF_SCOPE: 'add_service_out_of_scope',
    INVALID_FILE_EXTENSION: 'invalid_file_extension',
    DELETE_NON_EXISTING_FILES: 'delete_non_existing_files',
    UPDATE_FILE_NUM_LIMIT: 'update_file_num_limit',
    DESTINATION_BLANK: 'destination_blank',
    INVALID_GRANT: 'invalid_grant',
    NOT_UNIQUE: 'not_unique',
    CREATE_DE_FAIL: 'create_de_fail',
    CREATE_DE_IS_MEMBER: 'create_de_is_member',
    CREATE_DE_NOT_MEMBER: 'create_de_not_member',
    INVALID_MEMBER_NUMBER_FORMAT: 'invalid_member_number_format',
    INVALID_RECOVERY_TIME: 'invalid_recovery_time',
    INTERNAL_ERROR: 'internal_error',
    VEHICLE_REG_DATE_TIME: 'vehicle_reg_date_time',
    SHORT_PASSWORD: 'short_password',
    NOT_EMAIL: 'not_email',
    USER_DRIVER: 'user_is_driver',
    INVALID_PATTERN: 'invalid_pattern',
    NOT_UNIQUE_EXTERNAL_NUMBER: 'not_unique_external_number',
    ADDRESS_INCOMPLETE: 'address_incomplete',
    SERVICE_END_BEFORE_START: 'service_end_before_start',
    INVALID_SELECTION: 'invalid_selection',
};

export const validationMessagesTranslation = {
    [validationMessages.BAD_REQUEST]: 'Bad request.',
    [validationMessages.CONFLICT]: 'Conflict.',
    [validationMessages.ERROR]: 'Error.',
    [validationMessages.IS_BLANK]: 'Pflichtfeld. Bitte ausfüllen.',
    [validationMessages.IS_NULL]: 'Pflichtfeld. Bitte ausfüllen.',
    [validationMessages.OUT_OF_RANGE]: 'Pick-Up Distanz darf nur zwischen 101 und 400 km sein.',
    [validationMessages.MESSAGE_BAD_REQUEST]: 'Erstellung einer neuen Nachricht zur Rechnungsübermittlung fehlgeschlagen.',
    [validationMessages.IS_EMPTY]: 'Bitte Leistung auswählen.',
    [validationMessages.SERVICE_START_CURRENT]: 'Die Leistung darf nicht länger als 2 Jahre her sein.',
    [validationMessages.SERVICE_MIN_DURATION]: 'Die Leistungsdauer darf nicht kürzer als 5 min sein.',
    [validationMessages.INIT_SERVICE_OUT_OF_SCOPE]: 'Diese Leistungsarten sind nicht kombinierbar.',
    [validationMessages.ADD_SERVICE_OUT_OF_SCOPE]: 'Diese Zusatzleistungen passen nicht zur Hauptleistung.',
    [validationMessages.DELETE_NON_EXISTING_FILES]: 'Auf diese Datei konnte nicht zugegriffen werden.',
    [validationMessages.UPDATE_FILE_NUM_LIMIT]: 'Sie haben das Maximum an Anhängen erreicht.',
    [validationMessages.INVALID_FILE_EXTENSION]: 'Dieses Dateiformat unterstützen wir nicht.',
    [validationMessages.DESTINATION_BLANK]: 'Bitte Zielfeld ausfüllen',
    [validationMessages.INVALID_GRANT]: 'Wir konnten diese Anmeldedaten nicht finden. Bitte versuchen Sie es erneut.',
    [validationMessages.NOT_UNIQUE]: 'Diese Email-Adresse ist bereits registriert.',
    [validationMessages.CREATE_DE_FAIL]: 'Die Anlage eines neuen Falls hat nicht funktioniert.',
    [validationMessages.CREATE_DE_IS_MEMBER]: 'Gültige ACE-Mitgliedschaft vorhanden. Kosten werden voraussichtlich übernommen.',
    [validationMessages.CREATE_DE_NOT_MEMBER]: 'Keine Leistungsübernahme. Kosten werden voraussichtlich nicht von ACE übernommen.',
    [validationMessages.INVALID_MEMBER_NUMBER_FORMAT]: 'Diese Mitgliedsnummer scheint nicht das richtige Format zu haben. Sie sollte 10 Ziffern lang sein.',
    [validationMessages.INVALID_RECOVERY_TIME]: 'Bergungszeit ist pflichtfeld. Bitte ausfüllen.', // improvised translation
    [validationMessages.INTERNAL_ERROR]: 'Wir arbeiten mit Hochdruck an einer Lösung für dieses Problem. Bitte haben Sie etwas Geduld.',
    [validationMessages.VEHICLE_REG_DATE_TIME]: 'Das Jahr muss mindestens 1886 sein',
    [validationMessages.SHORT_PASSWORD]: 'Ihr Passwort muss mindestens 8 Zeichen haben.',
    [validationMessages.NOT_EMAIL]: 'E-Mail Adresse ist nicht gültig',
    [validationMessages.USER_DRIVER]: 'Bitte nutzen Sie als Fahrer die Fahrer-App',
    [validationMessages.INVALID_PATTERN]: 'Bitte geben Sie Ihre Telefonnummer mit Ländervorwahl ein.',
    [validationMessages.NOT_UNIQUE_EXTERNAL_NUMBER]: 'Diese Rechnungsnummer wurde bereits vergeben.',
    [validationMessages.ADDRESS_INCOMPLETE]: 'Bitte tragen Sie eine vollständige Adresse ein.',
    [validationMessages.SERVICE_END_BEFORE_START]: 'Die Endzeit darf nicht vor der Startzeit liegen.',
    [validationMessages.INVALID_SELECTION]: 'Diese Leistungsarten sind nicht kombinierbar.',
};

const vehicleValidationFields = {
    'model': 'Fahrzeugmodel',
    'manufacturer': 'Fahrzeughersteller',
    'licensePlate': 'Fahrzeug-Kennzeichen',
    'weight': 'Fahrzeuggewicht',
};

const memberValidationFields = {
    'member.type': 'Mitgliedstyp',
    'member.membershipNumber': 'Mitgliedschaftsnummer',
    'member.address.street': 'Mitgliederstraße',
    'member.address': 'Mitgliederadresse',
    'member.address.city': 'Mitgliederstadt',
    'member.address.country': 'Mitgliederland',
    'member.address.postCode': 'Mitglieder-Postleitzahl',
    'member.name': 'Mitgliedername',
};

const ownerValidationFields = {
    'owner.type': 'Beziehung zum Mitglied',
    'owner': 'Fahrzeughalterdaten',
    'owner.addresse': 'Fahrzeughalteradresse',
    'owner.address.street': 'Fahrzeughalterstraße',
    'owner.address.city': 'Fahrzeughalterstadt',
    'owner.address.country': 'Fahrzeughalterland',
    'owner.address.postCode': 'Fahrzeughalter-Postleitzahl',
    'owner.name': 'Fahrzeughaltername',
};

const driverValidationFields = {
    'driver': 'Fahrerdaten',
    'driver.type': 'Beziehung zum Mitglied',
    'driver.address.street': 'Fahrerstraße',
    'driver.address': 'Fahreradresse',
    'driver.address.city': 'Fahrerstadt',
    'driver.address.country': 'Fahrerland',
    'driver.address.postCode': 'Fahrer-Postleitzahl',
    'driver.name': 'Fahrername',
};

export const submitValidationTitles = {
    driverValidation: 'Fehlende Fahrerdaten',
    ownerValidation: 'Fehlende Fahrzeughalter',
    memberValidation: 'Fehlende Mitgliederdaten',
    vehicleValidation: 'Fehlende Fahrzeugdaten',
};

const startForm = ['assignment.serviceStartDateTime', 'assignment.serviceEndDateTime', 'assignment.billingType', 'assignment.damage.address.street', 'assignment.damage.address.postCode', 'assignment.damage.address.city', 'assignment.damage.address.country', 'assignment.damage.location.longitude', 'assignment.damage.location.latitude'];

const mainServicesSection = ['mainServices', 'mainServicesComment', 'assignment.emptyTripReason', 'assignment.journeyContinuation'];

const towingSection = ['assignment.towing.destinationType', 'assignment.towing.destinationName', 'assignment.towing.address.city', 'assignment.towing.address.street', 'assignment.towing.address.postCode', 'assignment.towing.address.country', 'assignment.towing.location.longitude', 'assignment.towing.location.latitude', 'assignment.towing.isInsideVpPolygon'];

const pickUpSection = ['assignment.pickup.destinationType', 'assignment.pickup.destinationName', 'assignment.pickup.address.city', 'assignment.pickup.address.street', 'assignment.pickup.address.postCode', 'assignment.pickup.address.country', 'assignment.pickup.distance'];

const recoverySection = ['assignment.startOfRecoveryDateTime', 'assignment.endOfRecoveryDateTime'];

const additionalServicesSection = ['additionalServices', 'additionalServicesComment'];

const setIsErrorOnSection = (errors, sectionErrors) => {
    return Object.keys(errors).some(err => {
        return sectionErrors.includes(err);
    });
};

export const setAnchorRefFromError = errors => {
    let anchor = 'additionalServicesSectionRef';
    switch (true) {
        case setIsErrorOnSection(errors, startForm):
            anchor = 'formStartRef';
            break;
        case setIsErrorOnSection(errors, mainServicesSection):
            anchor = 'mainServicesSectionRef';
            break;
        case setIsErrorOnSection(errors, recoverySection):
            anchor = 'recoverySectionRef';
            break;
        case setIsErrorOnSection(errors, towingSection):
            anchor = 'towingSectionRef';
            break;
        case setIsErrorOnSection(errors, pickUpSection):
            anchor = 'pickUpSectionRef';
            break;
        case setIsErrorOnSection(errors, additionalServicesSection):
            anchor = 'additionalServicesSectionRef';
            break;
    }
    return anchor;
};

export const translateErrorMessages = (field, errorCode) => {
    if ((errorCode === validationMessages.IS_NULL || errorCode === validationMessages.IS_BLANK)
        && !field.includes('assignment.towing.address')
        && !field.includes('assignment.towing.location')
        && !field.includes('assignment.towing.isInsideVpPolygon')
        && !field.includes('assignment.pickup.address')
        && !field.includes('assignment.pickup.location')
        && !field.includes('assignment.pickup.isInsideVpPolygon')) {
        return validationMessages.IS_BLANK;
    }
    let response;
    switch (field) {
        case 'mainServices':
            if (errorCode === validationMessages.INVALID_SELECTION) {
                response = validationMessages.INVALID_SELECTION;
            }
            if (errorCode === validationMessages.IS_EMPTY) {
                response = validationMessages.IS_EMPTY;
            }
            break;
        case 'assignment.emptyTripReason':
            response = validationMessages.IS_EMPTY;
            break;
        case 'assignment.serviceEndDateTime':
            if (errorCode === validationMessages.SERVICE_START_CURRENT) {
                response = validationMessages.SERVICE_START_CURRENT;
            }
            if (errorCode === validationMessages.SERVICE_MIN_DURATION) {
                response = validationMessages.SERVICE_MIN_DURATION;
            }
            if (errorCode === validationMessages.SERVICE_END_BEFORE_START) {
                response = validationMessages.SERVICE_END_BEFORE_START;
            }
            break;
        case 'assignment.pickup.distance':
            if (errorCode === validationMessages.OUT_OF_RANGE) {
                response = validationMessages.OUT_OF_RANGE;
            }
            break;
        case 'assignment.journeyContinuation':
            if (errorCode === validationMessages.INVALID_SELECTION) {
                response = validationMessages.INVALID_SELECTION;
            }
            break;
        case towingSection.find(towingField => (towingField.includes('assignment.towing.address')
                || towingField.includes('assignment.towing.location')
                || towingField.includes('assignment.towing.isInsideVpPolygon'))
            && field === towingField)
        || pickUpSection.find(pickupField => (pickupField.includes('assignment.pickup.address')
                || pickupField.includes('assignment.pickup.location')
                || pickupField.includes('assignment.pickup.isInsideVpPolygon'))
            && field === pickupField):
            if (errorCode === validationMessages.IS_BLANK || errorCode === validationMessages.IS_NULL) {
                response = validationMessages.ADDRESS_INCOMPLETE;
            }
            break;
        case 'attachments': {
            switch (errorCode) {
                case validationMessages.UPDATE_FILE_NUM_LIMIT:
                    response = validationMessages.UPDATE_FILE_NUM_LIMIT;
                    break;
                case validationMessages.DELETE_NON_EXISTING_FILES:
                    response = validationMessages.DELETE_NON_EXISTING_FILES;
                    break;
                case validationMessages.INVALID_FILE_EXTENSION:
                    response = validationMessages.INVALID_FILE_EXTENSION;
                    break;
            }
            break;
        }
        case 'password':
            if (errorCode === validationMessages.OUT_OF_RANGE) {
                response = validationMessages.SHORT_PASSWORD;
            }
            break;
        case 'email':
            if (errorCode === validationMessages.NOT_UNIQUE) {
                response = validationMessages.NOT_UNIQUE;
            }
            if (errorCode === validationMessages.NOT_EMAIL) {
                response = validationMessages.NOT_EMAIL;
            }
            break;

        case 'externalDocumentNumber':
            if (errorCode === validationMessages.NOT_UNIQUE) {
                response = validationMessages.NOT_UNIQUE_EXTERNAL_NUMBER;
            }
            break;
        case 'phoneNumber':
            if (errorCode === validationMessages.INVALID_PATTERN) {
                response = validationMessages.INVALID_PATTERN;
            }
            break;
        case 'text':
            if (errorCode === validationMessages.OUT_OF_RANGE) {
                response = validationMessages.MESSAGE_BAD_REQUEST;
            }
            break;
        default:
            response = validationMessagesTranslation[validationMessages.ERROR];
    }
    return response;
};

export const parseErrorMessages = (errorObject = {}) => {
    const errors = errorObject.errors ? errorObject.errors.reduce((acc, {field_name: field, error_code: errorCode}) => {
        return {
            ...acc,
            [field]: translateErrorMessages(field, errorCode)
            || validationMessagesTranslation[validationMessages.ERROR],
        };
    }, {}) : {};
    return {
        errors,
        ...(errorObject['error'] && {
            errorType: validationMessagesTranslation[errorObject['error']]
                || validationMessagesTranslation[validationMessages.ERROR],
        }),
        ...(errorObject['error_code'] && {
            errorType: validationMessagesTranslation[errorObject['error_code']]
                || validationMessagesTranslation[validationMessages.ERROR],
        }),
    };
};

export const scrollToAnchorWithOffset = (anchor, offset = 100) => {
    if (!anchor.current) {
        return;
    }
    const element = anchor.current;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - offset - document.body.getBoundingClientRect().top;
    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const getInvoiceSubmissionValidationErrorObject = errors => {
    const invoiceSubmissionSubmitErrorObject = {
        driverValidation: [],
        ownerValidation: [],
        memberValidation: [],
        vehicleValidation: [],
    };

    const errorTypes = Object.keys(errors);

    return errorTypes.reduce((errorObject, currentError) => {
        switch (true) {
            case !!driverValidationFields[currentError]:
                return {
                    ...errorObject,
                    driverValidation: [...errorObject.driverValidation, driverValidationFields[currentError]],
                };
            case !!memberValidationFields[currentError]:
                return {
                    ...errorObject,
                    memberValidation: [...errorObject.memberValidation, memberValidationFields[currentError]],
                };
            case !!ownerValidationFields[currentError]:
                return {
                    ...errorObject,
                    ownerValidation: [...errorObject.ownerValidation, ownerValidationFields[currentError]],
                };
            case !!vehicleValidationFields[currentError]:
                return {
                    ...errorObject,
                    vehicleValidation: [...errorObject.vehicleValidation, vehicleValidationFields[currentError]],
                };
            default:
                return errorObject;
        }
    }, invoiceSubmissionSubmitErrorObject);
};
