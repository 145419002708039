const {
    APP_VERSION,
    API_URL,
    DEPLOYMENT_ENV,
    KC_AUTH_REALM,
    KC_CLIENT_ID,
    KC_AUTH_URL,
    REDIRECTION_URL,
    DEUM_URL,
    DAF_SERVICE_URL,
    DL_SERVICE_URL,
    APM_SERVICE_URL,
    AMP_SERVICE_URL,
    EAF_SERVICE_URL,
    APS_SERVICE_URL,
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID,
    ELLA_LOCALE_TRANSLATION_EN_US_URL,
    ELLA_LOCALE_TRANSLATION_DE_DE_URL,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE,
    DATADOG_ENABLE,
} = process.env;

export default {
    APP_VERSION,
    API_URL,
    DEPLOYMENT_ENV,
    APM_SERVICE_URL,
    AMP_SERVICE_URL,
    DEUM_URL,
    DAF_SERVICE_URL,
    DL_SERVICE_URL,
    EAF_SERVICE_URL,
    APS_SERVICE_URL,
    KC_AUTH_REALM,
    KC_CLIENT_ID,
    KC_AUTH_URL,
    REDIRECTION_URL,
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_DATABASE_URL,
    FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID,
    ENDPOINTS: {
        DAMAGE_EVENTS: '/v1/damage-events',
        DAMAGE_EVENTS_V2: '/v2/damage-events',
        MEMBER: '/v1/member',
        INVOICE: '/v1/partners/current/invoices',
        MESSAGES: '/v1/damage-events',
        USERS: '/v1/users',
        FILES: '/v1/files',
        ATTACHMENTS: '/v1/attachments/get-upload-parameters',
        VP_CONTACTS: '/v1/vp-contacts',
        SUPPORT: '/v1/support',
    },
    DEFAULT_RECORDS_PER_PAGE: 10,
    DEFAULT_MESSAGES_REFRESH_TIME: 900000,
    PROD_DEPLOYMENT_ENV: 'prod',
    ELLA_LOCALE_TRANSLATION_EN_US_URL,
    ELLA_LOCALE_TRANSLATION_DE_DE_URL,
    DATADOG_RUM_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN,
    DATADOG_SERVICE,
    DATADOG_ENABLE,
};
