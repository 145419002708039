export const SET_INVOICE_SUBMISSION_REQUEST_STATE = 'SET_INVOICE_SUBMISSION_REQUEST_STATE';
export const CLEAR_INVOICE_SUBMISSION_DATA = 'CLEAR_INVOICE_SUBMISSION_DATA';
export const GET_INVOICE_SUBMISSIONS_REQUEST = 'GET_INVOICE_SUBMISSIONS_REQUEST';
export const GET_INVOICE_SUBMISSIONS_REQUEST_SENT = 'GET_INVOICE_SUBMISSIONS_REQUEST_SENT';
export const GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED = 'GET_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED';
export const GET_INVOICE_SUBMISSIONS_REQUEST_FAILED = 'GET_INVOICE_SUBMISSIONS_REQUEST_FAILED';

export const GET_INVOICE_SUBMISSION_REQUEST = 'GET_INVOICE_SUBMISSION_REQUEST';
export const GET_INVOICE_SUBMISSION_REQUEST_SENT = 'GET_INVOICE_SUBMISSION_REQUEST_SENT';
export const GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = 'GET_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const GET_INVOICE_SUBMISSION_REQUEST_FAILED = 'GET_INVOICE_SUBMISSION_REQUEST_FAILED';

export const SEARCH_INVOICE_SUBMISSION = 'SEARCH_INVOICE_SUBMISSION';
export const SET_INVOICE_SUBMISSION_SEARCH_STATE = 'SET_INVOICE_SUBMISSION_SEARCH_STATE';
export const CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS = 'CLEAR_INVOICE_SUBMISSION_SEARCH_RESULTS';

export const CHANGE_INVOICE_SUBMISSION_VIEW_STATE = 'CHANGE_INVOICE_SUBMISSION_VIEW_STATE';

export const STORE_OPEN_INVOICE_SUBMISSIONS = 'STORE_OPEN_INVOICE_SUBMISSIONS';
export const STORE_SUBMITTED_INVOICE_SUBMISSIONS = 'STORE_SUBMITTED_INVOICE_SUBMISSIONS';
export const STORE_ARCHIVE_INVOICE_SUBMISSIONS = 'STORE_ARCHIVE_INVOICE_SUBMISSIONS';
export const STORE_INVOICE_SUBMISSION_SEARCH_RESULTS = 'STORE_INVOICE_SUBMISSION_SEARCH_RESULTS';
export const STORE_INVOICE_SUBMISSION = 'STORE_INVOICE_SUBMISSION';
export const STORE_INVOICE_SUBMISSION_VIEW_STATE = 'STORE_INVOICE_SUBMISSION_VIEW_STATE';
export const STORE_FOLLOW_UP_INVOICE_SUBMISSION_MAIN_SERVICES = 'STORE_FOLLOW_UP_INVOICE_SUBMISSION_MAIN_SERVICES';

export const GET_CONTRACT_PARTNER_INFO_REQUEST = 'GET_CONTRACT_PARTNER_INFO_REQUEST';
export const GET_CONTRACT_PARTNER_INFO_REQUEST_SENT = 'GET_CONTRACT_PARTNER_INFO_REQUEST_SENT';
export const GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED = 'GET_CONTRACT_PARTNER_INFO_REQUEST_SUCCEEDED';
export const GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED = 'GET_CONTRACT_PARTNER_INFO_REQUEST_FAILED';

export const START_CREATE_INVOICE_SUBMISSION_FLOW = 'START_CREATE_INVOICE_SUBMISSION_FLOW';
export const START_CREATE_INVOICE_SUBMISSION_PRE_CHECK_FLOW = 'START_CREATE_INVOICE_SUBMISSION_PRE_CHECK_FLOW';
export const CREATE_INVOICE_SUBMISSION_PRE_CHECK_SELECT_OPTION = 'CREATE_INVOICE_SUBMISSION_PRE_CHECK_SELECT_OPTION';
export const SUBMIT_CHECK_MEMBER_STATUS_FORM = 'SUBMIT_CHECK_MEMBER_STATUS_FORM';
export const CREATE_INVOICE_SUBMISSION_PRE_CHECK_RESET = 'CREATE_INVOICE_SUBMISSION_PRE_CHECK_RESET';
export const CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_CURRENT_STEP = 'CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_CURRENT_STEP';
export const END_CREATE_INVOICE_SUBMISSION_FLOW = 'END_CREATE_INVOICE_SUBMISSION_FLOW';
export const SUBMIT_CREATE_INVOICE_SUBMISSION_FORM = 'SUBMIT_CREATE_INVOICE_SUBMISSION_FORM';
export const OPEN_CREATE_INVOICE_SUBMISSION_PRE_CHECK_MODAL = 'OPEN_CREATE_INVOICE_SUBMISSION_PRE_CHECK_MODAL';
export const CLOSE_CREATE_INVOICE_SUBMISSION_PRE_CHECK_MODAL = 'CLOSE_CREATE_INVOICE_SUBMISSION_PRE_CHECK_MODAL';
export const CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_OPTIONS = 'CREATE_INVOICE_SUBMISSION_PRE_CHECK_SET_OPTIONS';
export const SET_IS_CREATE_INVOICE_SUBMISSION_PRE_CHECK_SUCCESS = 'SET_IS_CREATE_INVOICE_SUBMISSION_PRE_CHECK_SUCCESS';


export const FETCH_MEMBER_STATUS_REQUEST_SENT = 'FETCH_MEMBER_STATUS_REQUEST_SENT';
export const FETCH_MEMBER_STATUS_REQUEST_SUCCEEDED = 'FETCH_MEMBER_STATUS_REQUEST_SUCCEEDED';
export const FETCH_MEMBER_STATUS_REQUEST_FAILED = 'FETCH_MEMBER_STATUS_REQUEST_FAILED';
export const FETCH_MEMBER_STATUS_REQUEST = 'FETCH_MEMBER_STATUS_REQUEST';

export const CREATE_INVOICE_SUBMISSION_REQUEST_SENT = 'CREATE_INVOICE_SUBMISSION_REQUEST_SENT';
export const CREATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = 'CREATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const CREATE_INVOICE_SUBMISSION_REQUEST_FAILED = 'CREATE_INVOICE_SUBMISSION_REQUEST_FAILED';
export const CREATE_INVOICE_SUBMISSION_REQUEST = 'CREATE_INVOICE_SUBMISSION_REQUEST';

export const STORE_CREATE_INVOICE_SUBMISSION_STATUS = 'STORE_CREATE_INVOICE_SUBMISSION_STATUS';


export const GET_PRICING_INFO_REQUEST = 'GET_PRICING_INFO_REQUEST';
export const GET_PRICING_INFO_REQUEST_SENT = 'GET_PRICING_INFO_REQUEST_SENT';
export const GET_PRICING_INFO_REQUEST_SUCCEEDED = 'GET_PRICING_INFO_REQUEST_SUCCEEDED';
export const GET_PRICING_INFO_REQUEST_FAILED = 'GET_PRICING_INFO_REQUEST_FAILED';

export const STORE_PRICING_INFO = 'STORE_PRICING_INFO';

export const UPDATE_INVOICE_SUBMISSION = 'UPDATE_INVOICE_SUBMISSION';

export const SUBMIT_INVOICE_SUBMISSION = 'SUBMIT_INVOICE_SUBMISSION';

export const CREATE_FOLLOW_UP_INVOICE_SUBMISSION = 'CREATE_FOLLOW_UP_INVOICE_SUBMISSION';

export const CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST = 'CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST';
export const CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_SENT = 'CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_SENT';
export const CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = 'CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_FAILED = 'CREATE_FOLLOW_UP_INVOICE_SUBMISSION_REQUEST_FAILED';

export const SUBMIT_INVOICE_SUBMISSION_REQUEST = 'SUBMIT_INVOICE_SUBMISSION_REQUEST';
export const SUBMIT_INVOICE_SUBMISSION_REQUEST_SENT = 'SUBMIT_INVOICE_SUBMISSION_REQUEST_SENT';
export const SUBMIT_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = 'SUBMIT_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const SUBMIT_INVOICE_SUBMISSION_REQUEST_FAILED = 'SUBMIT_INVOICE_SUBMISSION_REQUEST_FAILED';

export const UPDATE_INVOICE_SUBMISSION_REQUEST = 'UPDATE_INVOICE_SUBMISSION_REQUEST';
export const UPDATE_INVOICE_SUBMISSION_REQUEST_SENT = 'UPDATE_INVOICE_SUBMISSION_REQUEST_SENT';
export const UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED = 'UPDATE_INVOICE_SUBMISSION_REQUEST_SUCCEEDED';
export const UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED = 'UPDATE_INVOICE_SUBMISSION_REQUEST_FAILED';

export const UPDATE_VEHICLE_DATA = 'UPDATE_VEHICLE_DATA';
export const UPDATE_VEHICLE_DATA_REQUEST = 'UPDATE_VEHICLE_DATA_REQUEST';
export const UPDATE_VEHICLE_DATA_REQUEST_SENT = 'UPDATE_VEHICLE_DATA_REQUEST_SENT';
export const UPDATE_VEHICLE_DATA_REQUEST_SUCCEEDED = 'UPDATE_VEHICLE_DATA_REQUEST_SUCCEEDED';
export const UPDATE_VEHICLE_DATA_REQUEST_FAILED = 'UPDATE_VEHICLE_DATA_REQUEST_FAILED';

export const UPDATE_MEMBER_DATA = 'UPDATE_MEMBER_DATA';
export const UPDATE_MEMBER_DATA_REQUEST = 'UPDATE_MEMBER_DATA_REQUEST';
export const UPDATE_MEMBER_DATA_REQUEST_SENT = 'UPDATE_MEMBER_DATA_REQUEST_SENT';
export const UPDATE_MEMBER_DATA_REQUEST_SUCCEEDED = 'UPDATE_MEMBER_DATA_REQUEST_SUCCEEDED';
export const UPDATE_MEMBER_DATA_REQUEST_FAILED = 'UPDATE_MEMBER_DATA_REQUEST_FAILED';

export const DOWNLOAD_PDF_AND_CLOSE_SUBMIT_POPUP = 'DOWNLOAD_PDF_AND_CLOSE_SUBMIT_POPUP';
export const START_DOWNLOAD_PROOF_OF_SERVICE_FLOW = 'START_DOWNLOAD_PROOF_OF_SERVICE_FLOW';
export const STORE_SUBMIT_POPUP_STATUS = 'STORE_SUBMIT_POPUP_STATUS';
export const CLOSE_SUBMIT_POPUP = 'CLOSE_SUBMIT_POPUP';

export const START_UPDATE_EXTERNAL_STATUS_FLOW = 'START_UPDATE_EXTERNAL_STATUS_FLOW';

export const UPDATE_EXTERNAL_STATUS_REQUEST = 'UPDATE_EXTERNAL_STATUS_REQUEST';
export const UPDATE_EXTERNAL_STATUS_REQUEST_SENT = 'UPDATE_EXTERNAL_STATUS_REQUEST_SENT';
export const UPDATE_EXTERNAL_STATUS_REQUEST_SUCCEEDED = 'UPDATE_EXTERNAL_STATUS_REQUEST_SUCCEEDED';
export const UPDATE_EXTERNAL_STATUS_REQUEST_FAILED = 'UPDATE_EXTERNAL_STATUS_REQUEST_FAILED';

export const DOWNLOAD_INVOICE_SUBMISSION_PDF = 'DOWNLOAD_INVOICE_SUBMISSION_PDF';
export const DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST = 'DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST';
export const DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_SENT = 'DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_SENT';
export const DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_SUCCEEDED = 'DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_SUCCEEDED';
export const DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_FAILED = 'DOWNLOAD_INVOICE_SUBMISSION_PDF_REQUEST_FAILED';

export const DOWNLOAD_MULTIPLE_INVOICES = 'DOWNLOAD_MULTIPLE_INVOICES';
export const DOWNLOAD_MULTIPLE_INVOICES_REQUEST = 'DOWNLOAD_MULTIPLE_INVOICES_REQUEST';
export const DOWNLOAD_MULTIPLE_INVOICES_REQUEST_SENT = 'DOWNLOAD_MULTIPLE_INVOICES_REQUEST_SENT';
export const DOWNLOAD_MULTIPLE_INVOICES_REQUEST_SUCCEEDED = 'DOWNLOAD_MULTIPLE_INVOICES_REQUEST_SUCCEEDED';
export const DOWNLOAD_MULTIPLE_INVOICES_REQUEST_FAILED = 'DOWNLOAD_MULTIPLE_INVOICES_REQUEST_FAILED';

export const SET_DOWNLOAD_NOTIFICATION_MODAL_STATE = 'SET_DOWNLOAD_NOTIFICATION_MODAL_STATE';

export const EXPORT_INVOICE_SUBMISSIONS = 'EXPORT_INVOICE_SUBMISSIONS';
export const EXPORT_INVOICE_SUBMISSIONS_REQUEST = 'EXPORT_INVOICE_SUBMISSIONS_REQUEST';
export const EXPORT_INVOICE_SUBMISSIONS_REQUEST_SENT = 'EXPORT_INVOICE_SUBMISSIONS_REQUEST_SENT';
export const EXPORT_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED = 'EXPORT_INVOICE_SUBMISSIONS_REQUEST_SUCCEEDED';
export const EXPORT_INVOICE_SUBMISSIONS_REQUEST_FAILED = 'EXPORT_INVOICE_SUBMISSIONS_REQUEST_FAILED';

export const DOWNLOAD_BC_RECEIPT_PDF = 'DOWNLOAD_BC_RECEIPT_PDF';
export const DOWNLOAD_BC_RECEIPT_PDF_REQUEST = 'DOWNLOAD_BC_RECEIPT_PDF_REQUEST';
export const DOWNLOAD_BC_RECEIPT_PDF_REQUEST_SENT = 'DOWNLOAD_BC_RECEIPT_PDF_REQUEST_SENT';
export const DOWNLOAD_BC_RECEIPT_PDF_REQUEST_SUCCEEDED = 'DOWNLOAD_BC_RECEIPT_PDF_REQUEST_SUCCEEDED';
export const DOWNLOAD_BC_RECEIPT_PDF_REQUEST_FAILED = 'DOWNLOAD_BC_RECEIPT_PDF_REQUEST_FAILED';

export const DOWNLOAD_MULTIPLE_BC_RECEIPTS = 'DOWNLOAD_MULTIPLE_BC_RECEIPTS';
export const DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST = 'DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST';
export const DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_SENT = 'DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_SENT';
export const DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_SUCCEEDED = 'DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_SUCCEEDED';
export const DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_FAILED = 'DOWNLOAD_MULTIPLE_BC_RECEIPTS_REQUEST_FAILED';

export const GET_INVOICE_SUBMISSION_DETAILS = 'GET_INVOICE_SUBMISSION_DETAILS';
export const GET_INVOICE_SUBMISSION_DETAILS_REQUEST = 'GET_INVOICE_SUBMISSION_DETAILS_REQUEST';
export const GET_INVOICE_SUBMISSION_DETAILS_REQUEST_SENT = 'GET_INVOICE_SUBMISSION_DETAILS_REQUEST_SENT';
export const GET_INVOICE_SUBMISSION_DETAILS_REQUEST_FAILED = 'GET_INVOICE_SUBMISSION_DETAILS_REQUEST_FAILED';
export const GET_INVOICE_SUBMISSION_DETAILS_REQUEST_SUCCEEDED = 'GET_INVOICE_SUBMISSION_DETAILS_REQUEST_SUCCEEDED';

export const FETCH_SERVICE_FIXED_PRICE_FOR_SELECTED_DATE = 'FETCH_SERVICE_FIXED_PRICE_FOR_SELECTED_DATE';

export const GET_SERVICE_FIXED_PRICE_REQUEST = 'GET_SERVICE_FIXED_PRICE_REQUEST';
export const GET_SERVICE_FIXED_PRICE_REQUEST_SENT = 'GET_SERVICE_FIXED_PRICE_REQUEST_SENT';
export const GET_SERVICE_FIXED_PRICE_REQUEST_SUCCEEDED = 'GET_SERVICE_FIXED_PRICE_REQUEST_SUCCEEDED';
export const GET_SERVICE_FIXED_PRICE_REQUEST_FAILED = 'GET_SERVICE_FIXED_PRICE_REQUEST_FAILED';

export const GET_OPEN_CAR_MAX_PRICE_REQUEST = 'GET_OPEN_CAR_MAX_PRICE_REQUEST';
export const GET_OPEN_CAR_MAX_PRICE_REQUEST_SENT = 'GET_OPEN_CAR_MAX_PRICE_REQUEST_SENT';
export const GET_OPEN_CAR_MAX_PRICE_REQUEST_SUCCEEDED = 'GET_OPEN_CAR_MAX_PRICE_REQUEST_SUCCEEDED';
export const GET_OPEN_CAR_MAX_PRICE_REQUEST_FAILED = 'GET_OPEN_CAR_MAX_PRICE_REQUEST_FAILED';

export const GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST = 'GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST';
export const GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST_SENT = 'GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST_SENT';
export const GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST_SUCCEEDED = 'GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST_SUCCEEDED';
export const GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST_FAILED = 'GET_CONTRACT_PARTNER_FIXED_PRICE_REQUEST_FAILED';

export const STORE_SERVICE_FIXED_PRICES = 'STORE_SERVICE_FIXED_PRICES';
export const STORE_CONTRACT_PARTNER_FIXED_PRICES = 'STORE_CONTRACT_PARTNER_FIXED_PRICES';
export const STORE_OPEN_CAR_MAX_PRICES = 'STORE_OPEN_CAR_MAX_PRICES';
export const SET_PRICING_DATA_READY = 'SET_PRICING_DATA_READY';
